import { createSlice } from "@reduxjs/toolkit";
import { initWardState } from "./initWardState";

const initialWardsState = initWardState;
export const callTypes = {
	list: "list",
	action: "action",
};

export const wardsSlice = createSlice({
	name: "ward",
	initialState: initialWardsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		languageSelected: (state, action) => {
			const { language } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				selectedLanguage: language,
			};
		},
		// fetchAllwardDetails
		wardsFetched: (state, action) => {
			const { entities, wardDetails } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.wardDetails = wardDetails;
		},
		wardSelected: (state, action) => {
			const wardId = action.payload.id;
			return {
				...state,
				wardDetails: state.entities.ward.find((i) => i.ward == wardId),
				selectedHouseCount: state.houseCountList.ward.find(
					(i) => i.ward == wardId
				),
				selectedHouseType: state.houseTypeList.ward.find((i) => i.ward == wardId),
				selectedAnnualIncome: state.annualIncomeList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAnnualExpense: state.annualExpenseList.ward.find(
					(i) => i.ward == wardId
				),
				selectedHouseOwnerGender: state.houseOwnerGenderList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAbroad: state.abroadList.ward.find((i) => i.ward == wardId),
				selectedHealth: state.healthList.ward.find((i) => i.ward == wardId),
				selectedUnhealthy: state.unhealthyList.ward.find(
					(i) => i.ward == wardId
				),
				selectedDisable: state.disableList.ward.find((i) => i.ward == wardId),
				selectedDisableCardYesNo: state.disableCardYesNoList.ward.find(
					(i) => i.ward == wardId
				),
				selectedEducationSector: state.educationSectorList.ward.find(
					(i) => i.ward == wardId
				),
				selectedOccupation: state.occupationList.ward.find(
					(i) => i.ward == wardId
				),
				selectedEducation: state.educationList.ward.find(
					(i) => i.ward == wardId
				),
				selectedEthnics: state.ethnicsList.ward.find((i) => i.ward == wardId),
				selectedLivingType: state.livingTypeList.ward.find(
					(i) => i.ward == wardId
				),
				selectedReligion: state.religionList.ward.find((i) => i.ward == wardId),
				selectedMotherTongue: state.motherTongueList.ward.find(
					(i) => i.ward == wardId
				),
				selectedIsSchoolGoing: state.isSchoolGoingList.ward.find(
					(i) => i.ward == wardId
				),
				selectedMarital: state.maritalList.ward.find((i) => i.ward == wardId),
				selectedBankAccountYesNo: state.bankAccountYesNoList.ward.find(
					(i) => i.ward == wardId
				),
				selectedIsLoan: state.isLoanList.ward.find((i) => i.ward == wardId),
				selectedAgricultureProductionOrNot: state.agricultureProductionOrNotList.ward.find(
					(i) => i.ward == wardId
				),
				selectedLivestockRaising: state.livestockRaisingList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAnimalCount: state.animalCountList.ward.find(
					(i) => i.ward == wardId
				),
				selectedHouseCertificate: state.houseCertificateList.ward.find(
					(i) => i.ward == wardId
				),
				selectedHouseMapPass: state.houseMapPassList.ward.find(
					(i) => i.ward == wardId
				),
				selectedWaterResource: state.waterResourceList.ward.find(
					(i) => i.ward == wardId
				),
				selectedCookingSource: state.cookingSourceList.ward.find(
					(i) => i.ward == wardId
				),
				selectedElecticSource: state.electricSourceList.ward.find(
					(i) => i.ward == wardId
				),
				selectedToilet: state.toiletList.ward.find((i) => i.ward == wardId),
				selectedGarbage: state.garbageList.ward.find((i) => i.ward == wardId),
				selectedInstitutionCount: state.institutionCountList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAge: state.ageList.ward.find((i) => i.ward == wardId),
				selectedSocialAllowance: state.socialAllowanceList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAnnualAgriIncome: state.annualAgriIncomeList.ward.find(
					(i) => i.ward == wardId
				),
				selectedAnnualAgriExpense: state.annualAgriExpenseList.ward.find(
					(i) => i.ward == wardId
				),
				selectedSkill: state.skillList.ward.find((i) => i.ward == wardId),
				selectedDisableReason: state.disableReasonList.ward.find(
					(i) => i.ward == wardId
				),
				selectedHouseFoundation: state.houseFoundationList.ward.find(
					(i) => i.ward == wardId
				),
				selectedFoodForMonth: state.foodForMonthList.ward.find(
					(i) => i.ward == wardId
				),
				listLoading: false,
				error: null,
			};
		},
		populationFetched: (state, action) => {
			const { entities, wardDetails } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				entities: entities,
				wardDetails: wardDetails,
			};
		},
		educationSectorFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				educationSectorList: entities,
				selectedEducationSector: detail,
			};
		},
		healthFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				healthList: entities,
				selectedHealth: detail,
			};
		},
		unhealthyFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				unhealthyList: entities,
				selectedUnhealthy: detail,
			};
		},
		disableFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				disableList: entities,
				selectedDisable: detail,
			};
		},
		disableCardYesNoFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				disableCardYesNoList: entities,
				selectedDisableCardYesNo: detail,
			};
		},
		houseCountFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseCountList: entities,
				selectedHouseCount: detail,
			};
		},
		houseTypeFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseTypeList: entities,
				selectedHouseType: detail,
			};
		},
		annualIncomeFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				annualIncomeList: entities,
				selectedAnnualIncome: detail,
			};
		},
		annualExpenseFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				annualExpenseList: entities,
				selectedAnnualExpense: detail,
			};
		},
		houseOwnerGenderFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseOwnerGenderList: entities,
				selectedHouseOwnerGender: detail,
			};
		},
		abroadFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				abroadList: entities,
				selectedAbroad: detail,
			};
		},
		occupationFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				occupationList: entities,
				selectedOccupation: detail,
			};
		},
		educationFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				educationList: entities,
				selectedEducation: detail,
			};
		},
		ethnicsFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				ethnicsList: entities,
				selectedEthnics: detail,
			};
		},
		livingTypeFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				livingTypeList: entities,
				selectedLivingType: detail,
			};
		},
		religionFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				religionList: entities,
				selectedReligion: detail,
			};
		},
		motherTongueFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				motherTongueList: entities,
				selectedMotherTongue: detail,
			};
		},
		isSchoolGoingFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				isSchoolGoingList: entities,
				selectedIsSchoolGoing: detail,
			};
		},
		maritalFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				maritalList: entities,
				selectedMarital: detail,
			};
		},
		dashboardPercentageFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				dashboardPercentageList: entities,
				selectedDashboardPercentage: detail,
			};
		},
		bankAccountYesNoFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				bankAccountYesNoList: entities,
				selectedBankAccountYesNo: detail,
			};
		},
		isLoanFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				isLoanList: entities,
				selectedIsLoan: detail,
			};
		},
		agricultureProductionOrNotFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				agricultureProductionOrNotList: entities,
				selectedAgricultureProductionOrNot: detail,
			};
		},
		livestockRaisingFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				livestockRaisingList: entities,
				selectedLivestockRaising: detail,
			};
		},
		animalCountFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				animalCountList: entities,
				selectedAnimalCount: detail,
			};
		},
		houseCertificateFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseCertificateList: entities,
				selectedHouseCertificate: detail,
			};
		},
		houseMapPassFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseMapPassList: entities,
				selectedHouseMapPass: detail,
			};
		},
		waterResourceFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				waterResourceList: entities,
				selectedWaterResource: detail,
			};
		},
		cookingSourceFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				cookingSourceList: entities,
				selectedCookingSource: detail,
			};
		},
		electricSourceFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				electricSourceList: entities,
				selectedElectricSource: detail,
			};
		},
		toiletFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				toiletList: entities,
				selectedToilet: detail,
			};
		},
		garbageFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				garbageList: entities,
				selectedGarbage: detail,
			};
		},
		institutionCountFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				institutionCountList: entities,
				selectedInstitutionCount: detail,
			};
		},
		ageFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				ageList: entities,
				selectedAge: detail,
			};
		},
		socialAllowanceFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				socialAllowanceList: entities,
				selectedSocialAllowance: detail,
			};
		},
		annualAgriIncomeFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				annualAgriIncomeList: entities,
				selectedAnnualAgriIncome: detail,
			};
		},
		annualAgriExpenseFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				annualAgriExpenseList: entities,
				selectedAnnualAgriExpense: detail,
			};
		},
		skillFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				skillList: entities,
				selectedSkill: detail,
			};
		},
		disableReasonFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				disableReasonList: entities,
				selectedDisableReason: detail,
			};
		},
		houseFoundationFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				houseFoundationList: entities,
				selectedHouseFoundation: detail,
			};
		},
		foodForMonthFetched: (state, action) => {
			const { entities, detail } = action.payload;
			return {
				...state,
				listLoading: false,
				error: null,
				foodForMonthList: entities,
				selectedFoodForMonth: detail,
			};
		},
	},
});
