import React, { Component, useMemo } from "react";
import { Card, Table } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";
import BarGraphCard from "../components/BarGraphCard";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import getNepalNumber from "get-nepali-number";
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
class SurveyDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: null,
			section: "",
			selectedSection: "",
			selectedList: "",
			wardTableData: [],
		};
	}
	lowerFirstLetter(string) {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.detail[this.state.selectedSection]) {
			if (
				this.props.detail[this.state.selectedSection] !==
				nextProps.detail[this.state.selectedSection] ||
				this.props.selectedLanguage !== nextProps.selectedLanguage
			) {
				let barData;
				if (nextProps.detail[this.state.selectedSection]?.data?.male) {
					let femaleData = nextProps.detail[
						this.state.selectedSection
					].data.female.map(({ value }) => value);
					let maleData = nextProps.detail[
						this.state.selectedSection
					].data.male.map(({ value }) => value);
					let thirdData = nextProps.detail[
						this.state.selectedSection
					].data.third.map(({ value }) => value);
					let labelData = nextProps.detail[
						this.state.selectedSection
					].data.third.map((datum) => {
						return datum[nextProps.detail.selectedLanguage];
					});
					let femaleTitle =
						nextProps.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
					let maleTitle =
						nextProps.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
					let thirdTitle =
						nextProps.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
					barData = {
						id: 1,
						values: [
							{
								name: femaleTitle,
								data: femaleData,
							},
							{
								name: maleTitle,
								data: maleData,
							},
							{
								name: thirdTitle,
								data: thirdData,
							},
						],
						title:
							nextProps.detail[this.state.selectedList].title[
							nextProps.selectedLanguage
							],
						categories: labelData,
					};
				} else {
					let data = nextProps.detail[this.state.selectedSection]?.data?.map(
						({ value }) => value
					);
					let labelData = nextProps.detail[
						this.state.selectedSection
					]?.data?.map((datum) => {
						return datum[nextProps.detail.selectedLanguage];
					});
					barData = {
						id: 1,
						values: [
							{
								name: "",
								data: data,
							},
						],
						title:
							nextProps.detail[this.state.selectedList].title[
							nextProps.selectedLanguage
							],
						categories: labelData,
					};
				}
				this.setState({
					...this.state,
					barData,
					wardTableData: nextProps.detail[this.state.selectedList].ward,
				});
			}
		}
	}
	componentDidMount() {
		let pageAction = "fetch" + this.props.match.params.section;
		//console.log(pageAction);
		this.props.dispatch(actions[pageAction]());
		const selectedSection = "selected" + this.props.match.params.section;
		const selectedList =
			this.lowerFirstLetter(this.props.match.params.section) + "List";
		let barData;
		if (this.props.detail[selectedSection]?.data) {
			if (this.props.detail[selectedSection]?.data?.male) {
				let femaleData = this.props.detail[selectedSection].data.female.map(
					({ value }) => value
				);
				let maleData = this.props.detail[selectedSection].data.male.map(
					({ value }) => value
				);
				let thirdData = this.props.detail[selectedSection].data.third.map(
					({ value }) => value
				);
				let labelData = this.props.detail[selectedSection].data.third.map(
					(datum) => {
						return datum[this.props.detail.selectedLanguage];
					}
				);
				let femaleTitle =
					this.props.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
				let maleTitle =
					this.props.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
				let thirdTitle =
					this.props.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
				barData = {
					id: 1,
					values: [
						{
							name: femaleTitle,
							data: femaleData,
						},
						{
							name: maleTitle,
							data: maleData,
						},
						{
							name: thirdTitle,
							data: thirdData,
						},
					],
					title: this.props.detail[selectedList].title[
						this.props.selectedLanguage
					],
					categories: labelData,
					detail: "Education",
				};
			} else {
				let data = this.props.detail[selectedSection].data.map(
					({ value }) => value
				);
				let labelData = this.props.detail[selectedSection].data.map((datum) => {
					return datum[this.props.detail.selectedLanguage];
				});
				barData = {
					id: 1,
					values: [
						{
							name: "",
							data: data,
						},
					],
					title: this.props.detail[selectedList].title[
						this.props.selectedLanguage
					],
					categories: labelData,
					detail: "Education",
				};
			}
			this.setState({
				barData,
				selectedSection,
				selectedList,
				wardTableData: this.props.detail[selectedList].ward,
			});
		} else {
			let pageAction = "fetch" + this.props.match.params.section;
			this.props.dispatch(actions[pageAction]());
		}
	}
	clickDiv(el) {
		const downloadBtn = document.getElementById("test-table-xls-button");
		if (downloadBtn) {
			downloadBtn.click();
		}
	}
	// exportPdf = () => {
	//     // const doc = new jsPDF();

	//     const myFont = "../../assets/css/fonts/Kalimati-Regular.ttf" // load the *.ttf font file as binary string

	//     const filename = this.state.selectedList && this.props.detail[this.state.selectedList].title[this.props.selectedLanguage]
	//     const doc = new jsPDF('l', 'pt', 'a4')
	//     const htmlStr = document.getElementById("#detail-table")
	//     // add the font to jsPDF
	//     // doc.addFileToVFS("Kalimati-Regular.otf", myFont);
	//     // doc.addFont(myFont, "Kalimati-Regular", "normal");
	//     // doc.setFont("Kalimati-Regular")
	//     // autoTable(doc, { html: '#detail-table' })
	//     // doc.save('table.pdf')
	//     doc.autoTable({
	//         html: '#detail-table', styles: {
	//             font: 'Uninepali',
	//             fontStyle: 'normal',
	//         }
	//     })
	//     doc.save(`${filename}.pdf`)
	//     // const downloadBtn = document.getElementById("test-table-xls-button");
	// }
	getTotal = (datum) => {
		let total = 0;
		if (datum.data.sideTotal) {
			datum.data.sideTotal.map((value, index) => {
				total += value.value;
			});
			if (parseInt(datum.ward)) {
				return (
					<td>
						{this.props.selectedLanguage == "nepali"
							? getNepalNumber(total)
							: total}
					</td>
				);
			} else {
				return (
					<th>
						{this.props.selectedLanguage == "nepali"
							? getNepalNumber(total)
							: total}
					</th>
				);
			}
		} else {
			datum.data.map((value, index) => {
				total += value.value;
			});
			if (parseInt(datum.ward)) {
				return (
					<td>
						{this.props.selectedLanguage == "nepali"
							? getNepalNumber(total)
							: total}
					</td>
				);
			} else {
				return (
					<th>
						{this.props.selectedLanguage == "nepali"
							? getNepalNumber(total)
							: total}
					</th>
				);
			}
		}
	};
	render() {
		return (
			<>
				<div className="row">
					{this.state.barData && (
						<BarGraphCard
							datum={this.state.barData}
							section="bar_graph_detail"
							detail={true}
						/>
					)}
				</div>
				<Card className="my-10">
					<Card.Header>
						<div className="d-flex justify-content-between">
							<h2>
								{this.state.selectedList &&
									this.props.detail[this.state.selectedList].title[
									this.props.selectedLanguage
									]}
							</h2>
							<div>
								<ReactHTMLTableToExcel
									id="test-table-xls-button"
									className="d-none"
									table="detail-table"
									filename={
										this.state.selectedList &&
										this.props.detail[this.state.selectedList].title[
										this.props.selectedLanguage
										]
									}
									sheet={
										this.state.selectedList &&
										this.props.detail[this.state.selectedList].title[
										this.props.selectedLanguage
										]
									}
									buttonText="Download as XLS"
								/>
								<button
									className="btn btn-light-success"
									onClick={this.clickDiv}
								>
									{this.props.selectedLanguage == "nepali"
										? "डाउनलोडगर्नुहोस् XLS"
										: "Export XLS"}
									<i
										className="far fa-file-excel ml-2"
										style={{ top: "3px", position: "relative" }}
									></i>
								</button>
								{/* <button className="btn btn-light-danger" onClick={this.exportPdf}>
                                    Export PDF
                                <i className="far fa-file-pdf ml-2" style={{ top: "3px", position: "relative" }}></i>
                                </button> */}
							</div>
						</div>
					</Card.Header>
					<Card.Body>
						<Table responsive id="detail-table">
							<thead>
								<tr>
									<th>
										{this.props.selectedLanguage == "nepali"
											? "वडा न "
											: "Ward No. "}
									</th>
									{this.state.wardTableData.length &&
										this.state.wardTableData[0].data.male &&
										this.state.wardTableData[0].data.male.map(
											(datum, index) => {
												return <th>{datum[this.props.selectedLanguage]}</th>;
											}
										)}
									{this.state.wardTableData.length &&
										!this.state.wardTableData[0].data.male &&
										this.state.wardTableData[0].data.map((datum, index) => {
											return <th>{datum[this.props.selectedLanguage]}</th>;
										})}
									<th>
										{this.props.selectedLanguage == "nepali"
											? "जम्मा"
											: "Total"}
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.wardTableData.length &&
									this.state.wardTableData.map((datum, index) => {
										return (
											<tr key={index}>
												<th>{parseInt(
                                                                datum.ward
                                                            ) !== 0
                                                                ? this.props.selectedLanguage ==
                                                                  "nepali"
                                                                    ? getNepalNumber(
                                                                          datum.ward
                                                                      )
                                                                    : datum.ward
                                                                : this.props.selectedLanguage ==
                                                                  "nepali"
                                                                ? "मान्दावी"
                                                                : "Rural Municipality"}
												</th>
												{datum.data.sideTotal &&
													datum.data.sideTotal.map((valueObj, index) => {
														if (parseInt(datum.ward)) {
															return (
																<td key={index}>
																	{this.props.selectedLanguage == "nepali"
																		? getNepalNumber(valueObj.value)
																		: valueObj.value}
																</td>
															);
														} else {
															return (
																<th key={index}>
																	{this.props.selectedLanguage == "nepali"
																		? getNepalNumber(valueObj.value)
																		: valueObj.value}
																</th>
															);
														}
													})}
												{!datum.data.sideTotal &&
													datum.data.map((valueObj, index) => {
														if (parseInt(datum.ward)) {
															return (
																<td key={index}>
																	{this.props.selectedLanguage == "nepali"
																		? getNepalNumber(valueObj.value)
																		: valueObj.value}
																</td>
															);
														} else {
															return (
																<th key={index}>
																	{this.props.selectedLanguage == "nepali"
																		? getNepalNumber(valueObj.value)
																		: valueObj.value}
																</th>
															);
														}
													})}
												{// this.state
													this.getTotal(datum)}
											</tr>
										);
									})}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
	detail: state.ward,
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(SurveyDetail);
