import React, { Component, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { infastructure, gaupalika, ward, baseMap } from "../../variable/global";
import Axios from "../../redux/axiosHandlerGeo";
import { totalWard } from "../../variable/config";
import {
    MapContainer,
    TileLayer,
    LayersControl,
    GeoJSON,
    ZoomControl,
    ScaleControl,
} from "react-leaflet";
import GeoJsonLayer from "../sections/GeoJsonLayer";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { connect } from "react-redux";
import { BiAccessibility, BiCaretDown } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import "../../assets/css/MapDetailPage/sidebar.css";

const { BaseLayer } = LayersControl;

class MapDetailPage extends Component {
    // 	const [sidebar, setSidebar] = useState(true);
    // const showSidebar = () => setSidebar(!sidebar);

    constructor(props) {
        super(props);
        this.state = {
            barOpen: true,
            navOpen: false,
            navOpen0: false,
            navOpen1: false,
            navOpen2: false,
            navOpen3: false,
            navOpen4: false,
            navOpen5: false,
            navOpen6: false,
            navOpen7: false,
            navOpen8: false,
            navOpen9: false,
            navOpen10: false,
            navOpen11: false,
            navOpen12: false,
            navOpen13: false,
            navOpen14: false,
            navOpen15: false,
            navOpen16: false,
            navOpen17: false,
            navOpen18: false,
            navOpen19: false,
            navOpen20: false,
            modalShow: false,
            value: props.value,
            boundData: [],
            location: {
                lat: 28.379895,
                lng: 82.135121,
            },
            zoom: 12,
            checkbox: {
                economic: false,
            },
            wholeGeoJSON: {},
            showGeoJSON: [],
            wardCheckAll: false,
            indeterminateWard: false,
            baseLayers: {
                "No Basemap": "Empty",
                Streets: "streets",
                Satellite: "satellite",
                Grayscale: "grayscale",
            },
            // wardSelected: []
            wardSelected: [...Array(totalWard).keys()].map((each) => each + 1),
        };
        this.getMunicpalId = this.getMunicpalId.bind(this);
    }

    componentDidMount() {
        // setTimeout(() => {
        // 	let evt = {
        // 		target: {
        // 			checked: true,
        // 		},
        // 	};
        this.arrangeAllOption();
        // }, 2000);
    }

    isGeoJsonAlreadyFetched = (field) => {
        const { wholeGeoJSON } = this.state;
        //console.log("Geo json field", field);
        //console.log("Geo json", wholeGeoJSON);
        //console.log("Geo json fetched", wholeGeoJSON[field]);
        return Boolean(field in wholeGeoJSON);
    };

    fetchGeoJSON = (changedData) => {
        //console.log("fetch", changedData);
        const body = {
            ward: "fetch/ward",

            privateschool:
                "institutionfilter/Education/institute/education/instituteType/private",
            communityschool:
                "institutionfilter/Education/institute/education/instituteType/community",
            vedaschool:
                "institutionfilter/Education/institute/education/instituteType/veda",
            gumbaschool:
                "institutionfilter/Education/institute/education/instituteType/gumba",
            madrasaschool:
                "institutionfilter/Education/institute/education/instituteType/madrasa",
            universityschool:
                "institutionfilter/Education/institute/education/instituteType/university",
            collegeschool:
                "institutionfilter/Education/institute/education/instituteType/college",

            childschool:
                "institutionfilter/Education/institute/education/instituteLevel/child",
            aadhaarbhutschool:
                "institutionfilter/Education/institute/education/instituteLevel/aadhaarbhut",
            primaryschool:
                "institutionfilter/Education/institute/education/instituteLevel/primary_up_to_8",
            maadhyamikschool:
                "institutionfilter/Education/institute/education/instituteLevel/maadhyamik",
            maadhyamiknextschool:
                "institutionfilter/Education/institute/education/instituteLevel/maadhyamik_next",
            bachlorsschool:
                "institutionfilter/Education/institute/education/instituteLevel/bachlors",
            mastersschool:
                "institutionfilter/Education/institute/education/instituteLevel/masters",

            aclassbank: "institutionfilter/Bank/institute/economic/bankClass/a",
            bclassbank: "institutionfilter/Bank/institute/economic/bankClass/b",
            cclassbank: "institutionfilter/Bank/institute/economic/bankClass/c",
            dclassbank: "institutionfilter/Bank/institute/economic/bankClass/d",
            cooperativebank:
                "institutionfilter/Bank/institute/economic/bankClass/cooperative_pvt",
            insurancebank:
                "institutionfilter/Bank/institute/economic/bankClass/insurance",

            electricitygovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/electricity",
            forestgovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/forest_office",
            postgovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/post_office",
            wardgovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/ward_office",
            taxgovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/tax_office",
            weathergovernmentoffice:
                "institutionfilter/GovernmentOffice/institute/gov_office/governmentOfficeType/weather_office",

            ngoassociation:
                "institutionfilter/Institutions/institute/offices/institutionsType/ngo",
            privateassociation:
                "institutionfilter/Institutions/institute/offices/institutionsType/private",
            homelessassociation:
                "institutionfilter/Institutions/institute/offices/institutionsType/child_old_homeless",
            partyassociation:
                "institutionfilter/Institutions/institute/offices/institutionsType/party_office_1",

            policesecurity:
                "institutionfilter/SecurityBodies/institute/security/securityType/police",
            policeofficesecurity:
                "institutionfilter/SecurityBodies/institute/security/securityType/police_office",
            temporarypolicesecurity:
                "institutionfilter/SecurityBodies/institute/security/securityType/temporary_police_office",
            apfsecurity:
                "institutionfilter/SecurityBodies/institute/security/securityType/apf",
            trafficsecurity:
                "institutionfilter/SecurityBodies/institute/security/securityType/traffic",

            agriorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/agri_group",
            forestorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/forest_group",
            motherorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/mother_group",
            bachatorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/bachat_group",
            toleorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/tole_bikas_sanstha",
            paramparagatorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/paramparagat_group",
            childorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/child_club",
            youthorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/youth_club",
            apaangaorganization:
                "institutionfilter/CommunityOrganization/institute/community_based_office/groupType/apaanga_club",

            hospitalhealth:
                "institutionfilter/Health/institute/health/healthOrgType/hospital",
            healthposthealth:
                "institutionfilter/Health/institute/health/healthOrgType/healthPost",
            swasthyaikaaihealth:
                "institutionfilter/Health/institute/health/healthOrgType/swasthya_ikaai",
            primaryhealth:
                "institutionfilter/Health/institute/health/healthOrgType/primary_heath_center",
            basichealth:
                "institutionfilter/Health/institute/health/healthOrgType/basic_heath_center",
            urbanhealth:
                "institutionfilter/Health/institute/health/healthOrgType/urban_helath_center",
            villagehealth:
                "institutionfilter/Health/institute/health/healthOrgType/village_clinic",
            eyehealth:
                "institutionfilter/Health/institute/health/healthOrgType/eye_clinic",

            personalhealth:
                "institutionfilter/Health/institute/health/instituteType/personal",
            governmentalhealth:
                "institutionfilter/Health/institute/health/instituteType/governmental",

            kiranabusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/kirana",
            clothbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/cloth_shop",
            hardwarebusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/hardware",
            colddrinksbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/cold_drinks",
            electronicsbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/electronics",
            automobilebusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/auto_mobile",
            medicineclinicbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/medicine_and_clinic",
            hotelresturentbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/hotel_and_resturent",
            jwellerybusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/jwellery_shop",
            consultingbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/consulting",
            counselingbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/counseling",
            millbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/mill",
            utensilbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/utensil",
            parlorbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/parlor",
            meatbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/meat_shop",
            vegitebalefruitsbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/vegitebale_and_fruits_sell_store_1",
            dairybusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/milk_dairy",
            vetnarybusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/vetnary_shop",
            kewalbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/kewal_network",
            jimbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/jim",
            playgroundbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/football_play_ground_1",
            workshopbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/workshop",
            partypalacebusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/party_palace",
            sajawatbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/cold_drinks_and_sajawat_1",
            kawadibusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/kawadi_pasal",
            gasbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/gas_diler",
            petrolbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/petrol_pump",
            tenthousebusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/tent_house",
            menpowerbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/menpower",
            furnicherbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/furnicher_shope",
            tutionbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/tution_center_1",
            housingcompanybusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/housing_company",
            photostudiobusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/photo_studio",
            argfambusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/arg_fam",
            lawformbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/law_form",
            colourbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/colour_pasal",
            mallbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/mall_pasal",
            stationarybusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/stationary_pasal",
            godaambusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/godaam",
            othersbusiness:
                "institutionbusinessfilter/Business/Businnes/businessType/others",

            kaagajindustry:
                "institutionindusfilter/Industry/industries/industryCategory/kaagaj_udhog",
            galaichaindustry:
                "institutionindusfilter/Industry/industries/industryCategory/galaicha",
            brickindustry:
                "institutionindusfilter/Industry/industries/industryCategory/brick_industry",
            sugarindustry:
                "institutionindusfilter/Industry/industries/industryCategory/sugar_mill",
            krasarindustry:
                "institutionindusfilter/Industry/industries/industryCategory/KRASAR",
            woodindustry:
                "institutionindusfilter/Industry/industries/industryCategory/wooden_factory",
            bacakaryindustry:
                "institutionindusfilter/Industry/industries/industryCategory/bacakary",
            millindustry:
                "institutionindusfilter/Industry/industries/industryCategory/mill",
            grillindustry:
                "institutionindusfilter/Industry/industries/industryCategory/GRILL_INDUSTRIES",
            othersindustry:
                "institutionindusfilter/Industry/industries/industryCategory/others",

            houseindustry:
                "institutionbusinessfilter/Industry/industries/industryType/house_type_industries",
            mediumindustry:
                "institutionbusinessfilter/Industry/industries/industryType/medium_industries",
            bigindustry:
                "institutionbusinessfilter/Industry/industries/industryType/big_industries",
            laghuindustry:
                "institutionbusinessfilter/Industry/industries/industryType/laghu",
            otherstypeindustry:
                "institutionbusinessfilter/Industry/industries/industryType/others",

            bridgeinfrastructure:
                "institutionfilter/Bridge/Physical_Infrastructure/bridge/subCategory/bridge",
            stadiuminfrastructure:
                "institutionfilter/Ground/Physical_Infrastructure/stadium/subCategory/stadium",
            helipadinfrastructure:
                "institutionfilter/Helipad/Physical_Infrastructure/helipad/subCategory/helipad",
            electricityinfrastructure:
                "institutionfilter/Electricity/Physical_Infrastructure/electricity/subCategory/electricity",
            atminfrastructure:
                "institutionfilter/Atm/Physical_Infrastructure/ATM/subCategory/ATM",
            culvertinfrastructure:
                "institutionfilter/Culvert/Physical_Infrastructure/calvert/subCategory/calvert",
            buildinginfrastructure:
                "institutionfilter/CommunityBuilding/Physical_Infrastructure/community_building/subCategory/community_building",
            towerinfrastructure:
                "institutionfilter/Tower/Physical_Infrastructure/tower/subCategory/tower",
            tankinfrastructure:
                "institutionfilter/DrinkingWaterTank/Physical_Infrastructure/water_tank/subCategory/water_tank",
            crematoriainfrastructure:
                "institutionfilter/Crematorium/Physical_Infrastructure/sabdahaa_sthal_1/subCategory/sabdahaa_sthal_1",
            kuloinfrastructure:
                "institutionfilter/Irrigation/Physical_Infrastructure/baandh/subCategory/baandh",

            miningnature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/mining",
            waterfallnature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/waterfall",
            simsaarnature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/simsaar",
            cavenature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/cave",
            pondnature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/pond",
            lakenature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/lake",
            mainwaternature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/main_water_source",
            otherswaternature:
                "institutionindusfilter/NaturalResource/natural_resources/resourceType/others_water_source",

            toiletpublic:
                "institutionindusfilter/PublicToilet/public_place/subCategory/public_toilet",
            wifipublic:
                "institutionindusfilter/FreeWifi/public_place/subCategory/free_wifi",
            buspublic:
                "institutionindusfilter/BusStation/public_place/subCategory/buspark",
            safaripublic:
                "institutionindusfilter/BusStation/public_place/subCategory/safari_stand",
            chautaarapublic:
                "institutionindusfilter/ChautariPatiPauwa/public_place/subCategory/chautaara",
            patipauwapublic:
                "institutionindusfilter/ChautariPatiPauwa/public_place/subCategory/paati_pauwaa",
            parkpublic:
                "institutionindusfilter/Park/public_place/subCategory/park",

            templereligion:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/temple",
            mosquepublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/mosque",
            gumbapublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/gumba",
            dharmasalapublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/dharmasala",
            museumpublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/museum",
            palacepublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/palace",
            culturalclubpublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/cultural_club",
            charchpublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/charch",
            saakelathaanpublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/saakela_thaan",
            otherspublic:
                "institutionindusfilter/HistoricalReligiousSite/cultural_heritage_shm/subCategory/others",

            naturaldisaster:
                "institutionindusfilter/NaturalDisaster/disastar_risk_area_shm/parentCategory/disastar_risk_area_shm",

            touristspot:
                "institutionindusfilter/TourismPlace/tourist_spot/parentCategory/tourist_spot",

            wardofficespot:
                "institutionindusfilter/WardOfficeDetail/palika_related_office_shm/parentCategory/palika_related_office_shm",
        };

        return new Promise((resolve, reject) => {
            // //console.log("axiosUpper", body[changedData]);

            Axios.get(`/api/geojson/${body[changedData]}`)
                .then((result) => {
                    let wholeGeoJSON = this.state.wholeGeoJSON;
                    // wholeGeoJSON[changedData] = result.data;
                    this.setState(
                        {
                            wholeGeoJSON: {
                                ...wholeGeoJSON,
                                [changedData]: result.data,
                            },
                        },
                        this.arrangeAllOption
                    );
                })
                .catch((err) => reject());
        });
    };

    arrangeAllOption = async () => {
        const { checkbox, wardSelected, wardCheckAll } = this.state;
        //console.log("State:", this.state);
        //console.log("Ward selected", wardSelected);
        const showGeoJSON = [];
        console.log("Wholegeojson", this.state.wholeGeoJSON);
        if (!this.isGeoJsonAlreadyFetched("ward"))
            await this.fetchGeoJSON("ward");
        showGeoJSON.push(
            wardSelected.map((each) => this.state.wholeGeoJSON.ward[each - 1])
        );

        let dataToCheck = [];

        dataToCheck = dataToCheck.concat(infastructure);
        //console.log("dataToCheck", dataToCheck);
        const data = dataToCheck.map(async (each, i) => {
            if (each.children) {
                each.children.map(async (child) => {
                    if (checkbox[child.value]) {
                        //console.log("Checkbox", checkbox[child.value]);
                        if (!this.isGeoJsonAlreadyFetched(child.value))
                            await this.fetchGeoJSON(child.value);
                        if (wardCheckAll)
                            showGeoJSON.push(
                                this.state.wholeGeoJSON[child.value]
                            );
                        else
                            wardSelected.map((eachWard) => {
                                //console.log("ewar", eachWard);
                                showGeoJSON.push(
                                    Object.values(
                                        this.state.wholeGeoJSON[child.value]
                                    ).filter(
                                        (eachType) =>
                                            eachType.wardNo === eachWard
                                    )
                                );
                            });
                        console.log("ShowGeojson", showGeoJSON);
                    }
                });
            } else {
                if (checkbox[each.value]) {
                    //console.log("Checkbox", checkbox[each.value]);
                    if (!this.isGeoJsonAlreadyFetched(each.value))
                        await this.fetchGeoJSON(each.value);

                    if (wardCheckAll)
                        showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
                    else
                        wardSelected.map((eachWard) => {
                            showGeoJSON.push(
                                this.state.wholeGeoJSON[each.value].filter(
                                    (eachType) => eachType.wardNo === eachWard
                                )
                            );
                        });
                }
            }
        });
        // wait until all promises resolve
        await Promise.all(data);
        this.setState({ showGeoJSON });
    };

    onCheckAllWardChange = (e) => {
        this.setState((prev) => {
            return {
                wardSelected: !prev.wardCheckAll
                    ? [...Array(totalWard).keys()].map((each) => each + 1)
                    : [],
                indeterminateWard: false,
                wardCheckAll: !prev.wardCheckAll,
            };
        }, this.arrangeAllOption);
    };

    handleWardChange = (checkedValue) => {
        // const checkedValue = e.target.value;
        let wardSelected;
        let checkedValueInt = parseInt(checkedValue);
        if (this.state.wardSelected.includes(checkedValueInt)) {
            wardSelected = this.state.wardSelected
                .filter((i) => i !== checkedValueInt)
                .sort();
        } else {
            wardSelected = [...this.state.wardSelected, checkedValueInt].sort();
        }
        //console.log("wardarr", wardSelected);
        this.setState(
            {
                wardSelected,
                indeterminateWard:
                    !!checkedValue.length &&
                    checkedValue.length > 0 &&
                    checkedValue.length < totalWard,
                wardCheckAll: checkedValue.length === totalWard,
            },
            this.arrangeAllOption
        );
        //console.log(this.state.wardSelected);
    };

    handleChange = (e) => {
        e.persist();
        console.log("event", e.target.name);
        // e.persist();
        this.setState(
            ({ checkbox }) => ({
                checkbox: {
                    ...checkbox,
                    [e.target.name]: e.target.checked,
                },
            }),
            this.arrangeAllOption
        );
        //console.log(this.state);
    };

    getMunicpalId() {
        return (
            <div className="big-map">
                <MapContainer
                    style={{ height: "100vh" }}
                    center={this.state.location}
                    zoom={this.state.zoom}
                    zoomControl={false}
                    minZoom="11"
                >
                    <ScaleControl position="bottomright" />
                    <ZoomControl position="bottomright" />
                    <LayersControl position="bottomright">
                        {baseMap.map((each) => (
                            <BaseLayer
                                key={each.name}
                                checked={each.checked}
                                name={each.name}
                            >
                                <TileLayer
                                    attribution={each.attribution}
                                    url={each.url}
                                />
                            </BaseLayer>
                        ))}
                    </LayersControl>

                    {/* <TileLayer
                        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    /> */}

                    {/* {this.state.showGeoJSON.length > 0 &&
						this.state.showGeoJSON.map((each) => {
							return (
								each.length > 0 &&
								Object.values(each).map((eachGeoJSON, index) => {
									if (eachGeoJSON)
										return ( */}
                    <GeoJsonLayer showGeoJSON={this.state.showGeoJSON} />
                    {/* );
								})
							);
						})} */}
                </MapContainer>
            </div>
        );
    }

    getDropdownIcon = (icon) => {
        return (
            <img
                className="mr-3"
                src={toAbsoluteUrl(`/media/hamropalika/png-icons/${icon}.png`)}
                alt=""
                style={{ width: "40px" }}
            />
        );
    };

    getWardDropDown = () => {
        return (
            <>
                <div
                    className={
                        this.state.navOpen
                            ? "sidebar-item open"
                            : "sidebar-item"
                    }
                >
                    <div
                        className="sidebar-title"
                        onClick={() =>
                            this.setState({
                                navOpen:
                                    this.state.navOpen === true ? false : true,
                            })
                        }
                    >
                        <span>
                            {/* {this.props.selectedLanguage == "nepali" ? "वडा" : "Ward"} */}
                            {"वडा"}
                        </span>
                        <BiCaretDown className="toogle-button" />
                    </div>
                    <div className="sidebar-content">
                        {ward.map((each) => (
                            <FormControlLabel
                                className="sidebar-item plain"
                                control={
                                    <Checkbox
                                        checked={this.state.wardSelected.includes(
                                            parseInt(each.value)
                                        )}
                                        value={parseInt(each.value)}
                                        onChange={(e) =>
                                            this.handleWardChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                }
                                label={each["nepali"]}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    };

    getDropdownContent = () => {
        return infastructure.map((category, index) => {
            let navOpen = "navOpen" + index;
            return (
                <>
                    {/* {category.icon && this.getDropdownIcon(category.icon)} */}
                    {!category.value && (
                        <div>
                            <div
                                className={
                                    this.state[`${navOpen}`]
                                        ? "sidebar-item" + index + " open"
                                        : "sidebar-item" + index
                                }
                            >
                                <div
                                    className="sidebar-title"
                                    onClick={() =>
                                        this.setState({
                                            [`${navOpen}`]:
                                                this.state[`${navOpen}`] ===
                                                true
                                                    ? false
                                                    : true,
                                        })
                                    }
                                >
                                    <span>
                                        {category.icon &&
                                            this.getDropdownIcon(category.icon)}
                                        {category.nepali}{" "}
                                        {/* <span style={{ color: "red" }}>
                                            ({category?.count})
                                        </span> */}
                                        {/* {this.props.selectedLanguage == "nepali"
											? category.nepali
											: category.english} */}
                                    </span>
                                    <BiCaretDown className="toogle-button" />
                                </div>
                                <div className="sidebar-content">
                                    {category.children &&
                                        category.children.map((child) => (
                                            <FormControlLabel
                                                className="sidebar-item plain"
                                                control={
                                                    <Checkbox
                                                        name={child.value}
                                                        onChange={(e) =>
                                                            this.handleChange(e)
                                                        }
                                                        checked={
                                                            this.state.checkbox[
                                                                child.value
                                                            ]
                                                        }
                                                        value="checkedA"
                                                    />
                                                }
                                                label={child["nepali"]}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {category.value && (category.icon && this.getDropdownIcon(category.icon))} */}
                    {category.value && (
                        <FormControlLabel
                            className="sidebar-item plain"
                            control={
                                <Checkbox
                                    name={category.value}
                                    onChange={(e) => this.handleChange(e)}
                                    checked={
                                        this.state.checkbox[category.value]
                                    }
                                    value="checkedA"
                                />
                            }
                            label={category["nepali"]}
                        />
                    )}
                    {/* <span style={{ color: "red" }}>
                        {category.value && "(" + category?.count + ")"}
                    </span> */}
                </>
            );
        });
    };

    render() {
        return (
            <>
                <div className="map-content">
                    <div className="header-map">
                        <div id="map-headerLeft">
                            <a
                                href="#"
                                className="toggle-bars"
                                onClick={() =>
                                    this.setState({
                                        barOpen:
                                            this.state.barOpen === true
                                                ? false
                                                : true,
                                    })
                                }
                            >
                                {" "}
                                <AiOutlineMenu className="bars" />
                            </a>
                            <div id="map-headerText">
                                <h1 style={{ color: "#b83955" }}>
                                शारदा नगरपालिका
                                </h1>
                                {/* <p className="text-white d-none d-md-block">
                                    <strong>
                                        सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल
                                    </strong>
                                </p> */}
                            </div>
                        </div>

                        {/* <div className="search-bar">
                            <input
                                type="text"
                                name="text"
                                placeholder="Search..."
                                value={this.state.value}
                                autoFocus
                                onChange={(event) => {
                                    this.setState({
                                        value: event.target.value,
                                    });
                                }}
                                className="search"
                            />
                            <button type="submit" className="search-button">
                                <BsSearch />
                            </button>
                        </div>
                        <div></div> */}
                        <div className="center-content">डिजिटल स्रोत नक्शा</div>
                        <div>
                            <img
                                className="compass-png"
                                src={toAbsoluteUrl(
                                    `/media/hamropalika/png-icons/compass.png`
                                )}
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        className={
                            this.state.barOpen ? "sidebar active" : "sidebar"
                        }
                    >
                        {this.getWardDropDown()}
                        {this.getDropdownContent()}
                    </div>
                    {this.getMunicpalId()}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(MapDetailPage);
