/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkIsActive, toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export function StickySideMenu() {
    const location = useLocation();
    const [sections, setSections] = useState([]);

    React.useEffect(() => {
        $(".sticky-side-menu").removeClass("active");
    }, [location]);
    const { selectedLanguage } = useSelector(
        (state) => ({ selectedLanguage: state.ward.selectedLanguage }),
        shallowEqual
    );
    React.useEffect(() => {
        const val = [
            {
                title: "जनसंख्या विवरण",
                target: "numeric_stats",
                icon: "population",
                // icon: "media/hamropalika/svg/family.svg",
            },
            {
                title: "आर्थिक अवस्था विवरण",
                target: "economic_stats",
                icon: "economy",
                // icon: "media/hamropalika/svg/economic.svg",
            },
            {
                title: "कृषि चौपाया तथा पशुपन्छी विवरण",
                target: "farm_animals",
                icon: "farm",
                // icon: "media/hamropalika/svg/farm.svg",
            },
            {
                title: "स्वास्थ्य  विवरण",
                target: "health",
                icon: "hospital",
                // icon: "media/hamropalika/svg/hospital.svg",
            },
            {
                title: "शैक्षिक संस्था विवरण",
                target: "education",
                icon: "ic-borrowed-books",
                // icon: "media/hamropalika/svg/open-book.svg",
            },
            {
                title: "खाने पानी तथा सरसफाई सम्बन्धी विवरण",
                target: "sanitation",
                icon: "safety",
                // icon: "media/hamropalika/svg/safe.svg",
            },
            {
                title: "आवास तथा भवन सम्बन्धी विवरण",
                target: "buildings",
                icon: "home",
                // icon: "media/hamropalika/svg/home.svg",
            },
            // {
            //     title: "उद्योग, ब्यापार तथा बैंकिङ विवरण",
            //     target: "industrial_banking",
            //     icon: "banking",
            //     // icon: "media/hamropalika/svg/deal.svg",
            // },
            // {
            //     title: "नक्शा",
            //     target: "map",
            //     icon: "area-with-pins",
            //     icon: "media/hamropalika/svg/area-with-pins.svg",
            // },
            {
                title: "जन प्रतिनिधि ",
                target: "representative",
                icon: "leadership",
                // icon: "media/hamropalika/svg/leadership.svg",
            },
        ];

        let sectionTitles = [];
        if (selectedLanguage == "nepali") {
            sectionTitles = [
                "जनसंख्या विवरण",
                "आर्थिक अवस्था विवरण",
                "कृषि चौपाया तथा पशुपन्छी विवरण",
                // "उद्योग, ब्यापार तथा बैंकिङ विवरण",
                "स्वास्थ्य  विवरण",
                "शैक्षिक संस्था विवरण",
                "खाने पानी तथा सरसफाई सम्बन्धी विवरण",
                "आवास तथा भवन सम्बन्धी विवरण",
                // "संस्थागत विवरण",
                // "नक्शा",
                "जन प्रतिनिधि",
            ];
        } else {
            sectionTitles = [
                "Population Details",
                "Economic State Details",
                "Agricultural Livestock and Livestock Description",
                "Health details",
                "Educational Institution Details",
                "Drinking Water and Sanitation Details",
                "Accommodation and building details",
                // "Industry, Business and Banking Details",
                // "Map",
                "People's Representatives",
            ];
        }
        let titles = val.map((item, index) => {
            const newData = {
                ...item,
                title: sectionTitles[index],
            };
            return newData;
        });
        setSections(titles);
    }, [selectedLanguage]);
    function scrollToSectionById(id) {
        //console.log(id);
        var element = document.getElementById(id);
        const offset = 115;
        const bodyRect = document.body.getBoundingClientRect().top;
        if (element) {
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }
    window.onscroll = function() {
        var someElement = document.querySelector("#numeric_stats");
        var sideNavContents = document.querySelector(".nav-contents");
        //TOP
        // //console.log(someElement.getBoundingClientRect().top)
        if (someElement) {
            if (someElement.getBoundingClientRect().top <= 300) {
                $(".sticky-side-menu").addClass("active");
                // shift container add
                $(".custom-container").addClass("sticky-padding");
            } else {
                $(".sticky-side-menu").removeClass("active");
                // shift container remove
                $(".custom-container").removeClass("sticky-padding");
            }
        }
        // var distance = someElement.getBoundingClientRect().top;
        // //console.log(someElement.getBoundingClientRect().top)
        // //console.log(distance)

        var distance = $(window).scrollTop();
        $(".nav-content-item").each(function(i) {
            if ($(this).position().top <= distance + 250) {
                $(".sticky-side-menu li.active").removeClass("active");

                $(".sticky-side-menu li")
                    .eq(i)
                    .addClass("active");
            }
        });
    };
    $(".sidenav li").click(function() {
        // //console.log(n)
        $(".sidenav li").removeClass("active");
        setTimeout(() => {
            $(this).addClass("active");
        }, 180);
    });
    return (
        <>
            <ul className="sticky-side-menu nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                {sections.map((section, index) => {
                    return (
                        <OverlayTrigger
                            placement="right"
                            key={index}
                            overlay={
                                <Tooltip id="layout-tooltip">
                                    {section.title}{" "}
                                </Tooltip>
                            }
                        >
                            <li
                                className="nav-item mb-4"
                                data-placement="right"
                            >
                                <a
                                    className="btn btn-sm btn-icon btn-bg-light btn-text-primary "
                                    href="javascript:void()"
                                    onClick={(e) =>
                                        scrollToSectionById(section.target)
                                    }
                                >
                                    <i
                                        className={`icon icon-${section.icon} side-menu-item`}
                                    ></i>
                                    {/* <SVG src={toAbsoluteUrl(section.icon)} /> */}
                                </a>
                            </li>
                        </OverlayTrigger>
                    );
                })}
            </ul>
        </>
    );
}
