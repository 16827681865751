import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Fab } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import Pagination from "../components/Pagination";
import Axios from "../../redux/axiosHandlerGeo";
import ReactToPrint from "../components/ReactToPrint";
import "../../assets/css/staff.scss";
class RepresentativePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffList: [],
            totalPages: 0,
            employeeFilter: "",
            title: "सबै जन प्रतिनिधि / कर्मचारी",
            beforePrint: false,
            component: null,
            argumentTobePassed: [],
        };
    }

    handleAfterPrint = () => {
        this.setState({ beforePrint: false });
    };

    handleBeforePrint = () => {
        this.setState({ beforePrint: true });
    };

    getContent = () => {
        return this.setState(
            { beforePrint: true, component: this.componentRef },
            () => {
                return this.state.component;
            }
        );
    };

    componentDidMount() {
        Axios.get("/api/staff/public/all")
            .then((res) => {
                // const csvData = this.getCSVData(res.data);
                const pages = Math.ceil(res.data.length / 10);
                this.setState({
                    totalPages: pages,
                    staffList: res.data,
                    // csvData,
                });
            })
            .catch((err) => {
                console.log("dataSurvey err", err);
            });
    }

    // componentDidUpdate(prevProps) {
    // 	if (prevProps.employeeFilter !== this.props.employeeFilter) {
    // 		//console.log("updating!");
    // 		this.filterData();
    // 	}
    // }

    filterData = (data) => {
        Axios.post("/api/staff/filter", { employee: data })
            .then((res) => {
                console.log("filter", res.data);
                // const csvData = this.getCSVData(res.data);
                const pages = Math.ceil(res.data.length / 10);
                this.setState({
                    totalPages: pages,
                    staffList: res.data,
                    // csvData,
                });
            })
            .catch((err) => {
                console.log("dataSurvey err", err);
            });
    };

    noValue = (data) => {
        if (data) {
            return data;
        } else {
            return "N/A";
        }
    };

    handleEmployeeOption = (event) => {
        if (event.target.value === "old_employee") {
            this.setState({ title: "पुरानो कर्मचारी" });
        } else if (event.target.value === "current_employee") {
            this.setState({ title: "हालका कर्मचारी" });
        } else if (event.target.value === "janapratinidhi") {
            this.setState({ title: "जनप्रतिनिधि" });
        } else {
            this.setState({ title: "सबै जन प्रतिनिधि / कर्मचारी" });
        }
        this.setState({ staffList: [] });
        this.filterData(event.target.value);
    };

    handlePageChange = (val) => {
        const start = val * 10;
        const end = start + 10;
        const dispData = this.state.staffList.slice(start, end);
        this.setState({ staffList: dispData });
    };

    render() {
        return (
            <div className="container">
                <div className="staff-heading">
                    <h2 className="homepage-heading">{this.state.title}</h2>
                    <div className="staff-links">
                        <div>
                            <select
                                name="employee"
                                className="select"
                                onChange={this.handleEmployeeOption}
                            >
                                {/* <option selected disabled>
									{this.props.selectedLanguage === "nepali"
										? "एक विकल्प चयन गर्नुहोस्"
										: "Select an Option"}
								</option> */}
                                <option value="all">
                                    {this.props.selectedLanguage === "nepali"
                                        ? "सबै"
                                        : "Show All"}
                                </option>
                                <option value="old_employee">
                                    {this.props.selectedLanguage === "nepali"
                                        ? "पुरानो कर्मचारी"
                                        : "Old Employees"}
                                </option>
                                <option value="current_employee">
                                    {this.props.selectedLanguage === "nepali"
                                        ? "हालका कर्मचारी"
                                        : "current employee"}
                                </option>
                                <option value="janapratinidhi">
                                    {this.props.selectedLanguage === "nepali"
                                        ? "जनप्रतिनिधि"
                                        : "People's representative"}
                                </option>
                            </select>
                        </div>

                        <Button>
                            <ReactToPrint
                                style={{ marginTop: 200 }}
                                onBeforePrint={this.handleBeforePrint}
                                onAfterPrint={this.handleAfterPrint}
                                trigger={() => (
                                    // <Fab variant="extended" aria-label="Delete">
                                    <PrintIcon />
                                    // </Fab>
                                )}
                                data={this.state.component}
                                content={this.getContent}
                            />
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table
                            id="staff-table"
                            className="table table-image"
                            ref={(el) => (this.componentRef = el)}
                        >
                            <thead>
                                <tr id="table-head">
                                    <th scope="col">क्रम संख्या</th>
                                    <th scope="col">फोटो</th>
                                    <th scope="col">नाम</th>
                                    <th scope="col">पद</th>
                                    <th scope="col">शाखा</th>
                                    <th scope="col">फोन नम्बर</th>
                                    <th scope="col">ईमेल</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.staffList &&
                                    this.state.staffList.map((eachD, i) => (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td classNAme="w-25">
                                                <img
                                                    src={`https://mandavi.hamropalika.org/api/imageUpload/image/${eachD.uniqueIdentifier +
                                                        "_.jpg"}`}
                                                    class="img-fluid img-thumbnail"
                                                    alt="staff members"
                                                />
                                            </td>
                                            <td id="staff-name">
                                                {this.props.selectedLanguage ===
                                                "nepali"
                                                    ? eachD.name
                                                    : eachD.nameEnglish}
                                            </td>
                                            <td>
                                                {this.props.selectedLanguage ===
                                                "nepali"
                                                    ? this.noValue(
                                                          eachD.designation
                                                      )
                                                    : this.noValue(
                                                          eachD.designationEnglish
                                                      )}
                                            </td>
                                            {eachD.hasOwnProperty('sakha') ? 
                                            <td>
                                                {this.props.selectedLanguage ===
                                                "nepali"
                                                    ? this.noValue(eachD.sakha)
                                                    : this.noValue(
                                                          eachD.sakhaEnglish
                                                      )}
                                            </td> : <div></div>
                                            }

                                            <td>
                                                {this.props.selectedLanguage ===
                                                "nepali"
                                                    ? this.noValue(
                                                          eachD.phoneNumber
                                                      )
                                                    : this.noValue(
                                                          eachD.phoneNumberEnglish
                                                      )}
                                            </td>
                                            <td>{this.noValue(eachD.email)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <Pagination
                            pages={this.state.totalPages}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ ward: { selectedLanguage } }) => ({
    selectedLanguage,
});

export default connect(mapStateToProps)(RepresentativePage);
