import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Card } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { isEmpty } from "lodash";

const PersonCard = (props) => {
    const { selectedLanguage } = useSelector(
        (state) => ({ selectedLanguage: state.ward.selectedLanguage }),
        shallowEqual
    );

    const noValue = (data) => {
        if (data) {
            return data;
        } else {
            return "N/A";
        }
    };
    return (
        <div className={`col-sm-3 col-md-6 col-xl-3`}>
            {/* <div className={`mb-4 ${props.data.containerClass}`}> */}
            <Card className="h-100">
                <Card.Body>
                    <div className="d-flex  justify-content-center align-items-center flex-column">
                        <img
                            src={`https://mandavi.hamropalika.org/api/imageUpload/image/${props
                                .data.uniqueIdentifier + "_.jpg"}`}
                            alt=""
                            className=" mb-4 representative"
                        />
                        <div className="text-center">
                            <h6 className="mb-3 ">
                                {selectedLanguage == "nepali"
                                    ? props.data.name
                                    : props.data.nameEnglish}
                            </h6>
                            <h6 className="mb-3">
                                {selectedLanguage == "nepali"
                                    ? props.data.designation
                                    : props.data.designationEnglish}
                            </h6>
                        </div>
                    </div> 
                    <div className="person-details">
                    {props.data.hasOwnProperty('sakha') ?
                        <div className="row">
                            <div className="col-4">
                                <h6>
                                    {selectedLanguage == "nepali"
                                        ? "शाखा:"
                                        : "branch:"}
                                </h6>
                            </div>
                            <div className="col-8">
                                <h6
                                    style={{
                                        fontFamily:
                                            '"Roboto", "Helvetica", "Arial", sans-serif',
                                    }}
                                >
                                    {selectedLanguage == "nepali"
                                        ? noValue(props.data.sakha)
                                        : noValue(props.data.sakhaEnglish)}
                                </h6>
                            </div>
                        </div>: <div></div>
                    }
                    
                        <div className="row">
                            <div className="col-4">
                                <h6>
                                    {selectedLanguage == "nepali"
                                        ? "फोन:"
                                        : "Phone:"}
                                </h6>
                            </div>
                            <div className="col-8">
                                <h6>
                                    {selectedLanguage == "nepali"
                                        ? noValue(props.data.phoneNumber)
                                        : noValue(
                                              props.data.phoneNumberEnglish
                                          )}
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <h6>
                                    {selectedLanguage == "nepali"
                                        ? "ठेगाना :"
                                        : "Address:"}
                                </h6>
                            </div>
                            <div className="col-8">
                                <h6>
                                    {selectedLanguage == "nepali"
                                        ? noValue(props.data.address)
                                        : noValue(props.data.addressEnglish)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default PersonCard;
