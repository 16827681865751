export const gaupalikaNepali = "शारदा";
export const gaupalikaEnglish = "Sharada";
export const ruralMunicipalNepali = "नगरपालिका";
export const ruralMunicipalEnglish = "Municipality";
export const gaupalika = { nepali: gaupalikaNepali, english: gaupalikaEnglish };
// export const infastructure = [
// 	{
// 		icon: "book",
// 		english: "Educational Institution",
// 		nepali: "शैक्षिक सँस्था",
// 		children: [
// 			// {
// 			// 	value: "private",
// 			// 	english: "Private",
// 			// 	nepali: "निजि",
// 			// },
// 			{
// 				value: "primary",
// 				english: "Primary",
// 				nepali: "प्राथमिक",
// 			},
// 			{
// 				value: "secondary",
// 				english: "Secondary",
// 				nepali: "माध्यमिक",
// 			},
// 			// {
// 			// 	value: "montessori",
// 			// 	english: "Montessori",
// 			// 	nepali: "मोन्टेसरी",
// 			// },
// 			{
// 				value: "college_technical",
// 				english: "Technical College",
// 				nepali: "टेक्निकल कलेज",
// 			},
// 			// {
// 			// 	value: "college_nontechnical",
// 			// 	english: "Nontechnical College",
// 			// 	nepali: "कलेज",
// 			// },
// 			// {
// 			// 	value: "skill_center",
// 			// 	english: "Skill Center",
// 			// 	nepali: "कौशल केन्द्र",
// 			// },
// 			// {
// 			// 	value: "university",
// 			// 	english: "University",
// 			// 	nepali: "विश्वविद्यालय",
// 			// },
// 			// {
// 			// 	value: "community",
// 			// 	english: "Community",
// 			// 	nepali: "सामुदायिक",
// 			// },
// 			// {
// 			// 	value: "madrasa",
// 			// 	english: "Madrasa",
// 			// 	nepali: "मदरसा",
// 			// },
// 			// {
// 			// 	value: "gumba",
// 			// 	english: "Gumba",
// 			// 	nepali: "गुम्बा",
// 			// },
// 			// {
// 			// 	value: "veda",
// 			// 	english: "Vidya Asram",
// 			// 	nepali: "वेद विद्याश्रम",
// 			// },
// 			// {
// 			// 	value: "library",
// 			// 	english: "Library",
// 			// 	nepali: "पुस्तकालय",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "hospital",
// 		english: "Health Institution",
// 		nepali: "स्वास्थ केन्द्र",
// 		children: [
// 			// {
// 			// 	value: "hospital",
// 			// 	english: "Hospital",
// 			// 	nepali: "अस्पताल",
// 			// },
// 			{
// 				value: "healthPost",
// 				english: "Health Post",
// 				nepali: "स्वाथ्य चौकी",
// 			},
// 			// {
// 			// 	value: "urban_helath_center",
// 			// 	english: "Urban Helath Center",
// 			// 	nepali: "शहरी स्वास्थ्य केन्द्र",
// 			// },
// 			{
// 				value: "basic_health_center",
// 				english: "Basic Health Center",
// 				nepali: "आधारभूत स्वास्थ्य सेवा केन्द्र",
// 			},
// 			{
// 				value: "village_clinic",
// 				english: "Village Clinic",
// 				nepali: "गाउँ घर क्लिनिक",
// 			},
// 			// {
// 			// 	value: "helpdesk",
// 			// 	english: "Helpdesk",
// 			// 	nepali: "हेल्पडेस्क",
// 			// },
// 			// {
// 			// 	value: "maternity",
// 			// 	english: "Maternity",
// 			// 	nepali: "प्रसुती केन्द्र",
// 			// },
// 			// {
// 			// 	value: "clinic",
// 			// 	english: "Clinic",
// 			// 	nepali: "क्लिनिक",
// 			// },
// 			// {
// 			// 	value: "ayurved",
// 			// 	english: "Ayurved",
// 			// 	nepali: "आयुर्वेद",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "infrastructure",
// 		english: "Infrastructure",
// 		nepali: "भौतिक पूर्वाधार",
// 		children: [
// 			{
// 				value: "bridge",
// 				english: "Bridge",
// 				nepali: "पुल",
// 			},
// 			// {
// 			// 	value: "fm_station",
// 			// 	english: "F.M Station",
// 			// 	nepali: "एफ.एम स्टेशन",
// 			// },
// 			{
// 				value: "tower",
// 				english: "Tower (NTC/NCELL/OTHER)",
// 				nepali: "संचार टावर (NTC/NCELL/OTHER)",
// 			},
// 			{
// 				value: "water_tank",
// 				english: "Water Tank",
// 				nepali: "खानेपानी ट्यांकी भएको स्थान",
// 			},
// 			{
// 				value: "community_building",
// 				english: "Community Building",
// 				nepali: "सामुदायिक भवन",
// 			},
// 			{
// 				value: "calvert",
// 				english: "Calvert",
// 				nepali: "कल्भर्ट",
// 			},
// 			// {
// 			// 	value: "internet_provider",
// 			// 	english: "Internet Provider",
// 			// 	nepali: "इन्टरनेट प्रदायक संस्था",
// 			// },
// 			// {
// 			// 	value: "stadium",
// 			// 	english: "Stadium",
// 			// 	nepali: "रंगशाला",
// 			// },
// 			// {
// 			// 	value: "sabhaa_hal",
// 			// 	english: "Sabhaa Hall",
// 			// 	nepali: "सभा हल",
// 			// },
// 			// {
// 			// 	value: "airport",
// 			// 	english: "Airport",
// 			// 	nepali: "विमानस्थल",
// 			// },
// 			// {
// 			// 	value: "tv_station",
// 			// 	english: "T.V Station",
// 			// 	nepali: "टिभी स्टेशन",
// 			// },
// 			// {
// 			// 	value: "powerhouse",
// 			// 	english: "Power House",
// 			// 	nepali: "पावरहाउस (जलविधुत आयोजना)",
// 			// },
// 			// {
// 			// 	value: "powerstation",
// 			// 	english: "Power Station",
// 			// 	nepali: "पावरस्टेशन",
// 			// },
// 		],
// 	},
// 	{
// 		icon: "banking",
// 		english: "Financial Institutions",
// 		nepali: "बैंक तथा वित्तीय संस्था",
// 		children: [
// 			{
// 				value: "a",
// 				english: "A Class",
// 				nepali: "क बर्ग",
// 			},
// 			{
// 				value: "b",
// 				english: "B Class",
// 				nepali: "ख बर्ग",
// 			},
// 			{
// 				value: "c",
// 				english: "C CLass",
// 				nepali: "ग वर्ग",
// 			},
// 		],
// 	},
// 	{
// 		icon: "park",
// 		english: "Public Places",
// 		nepali: "सार्वजनिक स्थल",
// 		children: [
// 			{
// 				value: "public_toilet",
// 				english: "Public Toilet",
// 				nepali: "सर्वाजनिक सौचालय",
// 			},
// 			{
// 				value: "free_wifi",
// 				english: "Free Wifi",
// 				nepali: "फ्री वाईफाई जोन",
// 			},
// 			{
// 				value: "buspark",
// 				english: "Buspark",
// 				nepali: "वसपार्क",
// 			},
// 			{
// 				value: "park",
// 				english: "Park",
// 				nepali: "पार्क",
// 			},
// 			{
// 				value: "zoo",
// 				english: "Zoo",
// 				nepali: "चिडिया घर",
// 			},
// 		],
// 	},
// 	{
// 		icon: "government",
// 		english: "Government Offices",
// 		nepali: "सरकारी कार्यालयहरु",
// 		children: [
// 			{
// 				value: "land_revenue",
// 				english: "Land Revenue",
// 				nepali: "मालपोत कार्यालय",
// 			},
// 			{
// 				value: "dao",
// 				english: "District Office",
// 				nepali: "जील्ला प्रशासन कार्यालय",
// 			},
// 			{
// 				value: "court",
// 				english: "Court",
// 				nepali: "अदालत",
// 			},
// 			{
// 				value: "ministries",
// 				english: "Ministries",
// 				nepali: "मंत्रालय",
// 			},
// 			{
// 				value: "electricity",
// 				english: "Electricity Office",
// 				nepali: "विधुत प्राधिकरण कार्यालय",
// 			},
// 			{
// 				value: "dcc",
// 				english: "District Council",
// 				nepali: "जिल्ला समन्व समिति कार्यालय",
// 			},
// 		],
// 	},
// 	{
// 		icon: "building",
// 		english: "Organizations",
// 		nepali: "संघ सँस्थाहरु",
// 		children: [
// 			{
// 				value: "ngo",
// 				english: "NGO",
// 				nepali: "गैरसरकारी",
// 			},
// 			{
// 				value: "ingo",
// 				english: "INGO",
// 				nepali: "अन्तर्राष्ट्रिय  गैरसरकारी",
// 			},
// 			{
// 				value: "private",
// 				english: "Private / Public",
// 				nepali: "निजी / पब्लीक",
// 			},
// 		],
// 	},
// 	{
// 		icon: "factory",
// 		english: "Industries",
// 		nepali: "उधोग तथा कलकारखाना",
// 		children: [
// 			{
// 				value: "brick_industry",
// 				english: "Brick Industry",
// 				nepali: "इँटा भट्टा",
// 			},
// 			{
// 				value: "sugar_mill",
// 				english: "Sugar Mill",
// 				nepali: "चिनी उधोग",
// 			},
// 			{
// 				value: "mill",
// 				english: "Mill",
// 				nepali: "मिल",
// 			},
// 		],
// 	},
// 	{
// 		icon: "police-station",
// 		english: "Security body Offices",
// 		nepali: "सुरक्षा निकाय सम्बन्धी",
// 		children: [
// 			{
// 				value: "police",
// 				english: "Police Station",
// 				nepali: "प्रहरी चौकी",
// 			},
// 			{
// 				value: "apf",
// 				english: "APF Camp",
// 				nepali: "एपीएफ व्यारेक",
// 			},
// 			{
// 				value: "army",
// 				english: "Army Camp",
// 				nepali: "सैनिक व्यारेक",
// 			},
// 			{
// 				value: "traffic",
// 				english: "Traffic Office",
// 				nepali: "ट्राफिक कार्यालय",
// 			},
// 		],
// 	},
// 	{
// 		icon: "nature",
// 		english: "Natural Resources",
// 		nepali: "प्राकृतिक श्रोत",
// 		children: [
// 			{
// 				value: "mining",
// 				english: "Mine Area",
// 				nepali: "खानी क्षेत्र",
// 			},
// 			{
// 				value: "waterfall",
// 				english: "Waterfall",
// 				nepali: "झरना",
// 			},
// 			{
// 				value: "cave",
// 				english: "Cave",
// 				nepali: "गुफा",
// 			},
// 			{
// 				value: "lake",
// 				english: "Lake",
// 				nepali: "ताल",
// 			},
// 			{
// 				value: "pond",
// 				english: "Pond",
// 				nepali: "पोखरी",
// 			},
// 			{
// 				value: "main_water_source",
// 				english: "Main Watersource",
// 				nepali: "मुख्य पानी मुहान",
// 			},
// 		],
// 	},
// 	// {
// 	// 	icon: "book",
// 	// 	value: "industries",
// 	// 	english: "Industries",
// 	// 	nepali: "उधोग तथा कलकारखाना",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "cultural_heritage",
// 	// 	english: "Cultural Heritage",
// 	// 	nepali: "साँस्कृतिक सम्पदाहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "gov_office",
// 	// 	english: "Government Offices",
// 	// 	nepali: "सरकारी कार्यालयहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "forest",
// 	// 	english: "Forest",
// 	// 	nepali: "सामुदायिक वन",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "education",
// 	// 	english: "Educational Institution",
// 	// 	nepali: "शैक्षिक सँस्था",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "offices",
// 	// 	english: "Organizations",
// 	// 	nepali: "संघ सँस्थाहरु",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "infrastructure",
// 	// 	english: "Infrastructure",
// 	// 	nepali: "भौतिक पूर्वाधार",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "security",
// 	// 	english: "Security body Offices",
// 	// 	nepali: "सुरक्षा निकाय सम्बन्धी",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "economic",
// 	// 	english: "Financial Institutions",
// 	// 	nepali: "बैंक तथा वित्तीय संस्था",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "palika_related_office",
// 	// 	english: "Municipal and Ward Office",
// 	// 	nepali: "गाउँ/नगर केन्द्र र वडा कार्यालय ",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "public_places",
// 	// 	english: "Public Places",
// 	// 	nepali: "सार्वजनिक स्थल",
// 	// },
// 	// {
// 	// 	icon: "book",
// 	// 	value: "health",
// 	// 	english: "Health Institution",
// 	// 	nepali: "स्वास्थ केन्द्र",
// 	// },
// ];

export const infastructure = [
    {
        icon: "book",
        english: "Educational Institution",
        nepali: "शैक्षिक संस्थाको प्रकार",
        count: 106,
        children: [
            {
                value: "privateschool",
                english: "Private",
                nepali: "निजि",
            },
            {
                value: "communityschool",
                english: "Basic School",
                nepali: "सामुदायिक",
            },
            {
                value: "gumbaschool",
                english: "Gumba",
                nepali: "गुम्बा",
            },
            {
                value: "vedaschool",
                english: "Vidya Asram",
                nepali: "वेद विद्याश्रम",
            },
            {
                value: "madrasaschool",
                english: "Madrasa",
                nepali: "मदरसा",
            },
            {
                value: "universityschool",
                english: "University",
                nepali: "विश्वविद्यालय",
            },
            {
                value: "collegeschool",
                english: "College / Campus",
                nepali: "कलेज / क्याम्पस",
            },
        ],
    },
    {
        icon: "book",
        english: "Educational Institution",
        nepali: "शैक्षिक संस्थाको तह",
        count: 106,
        children: [
            {
                value: "childschool",
                english: "Child Development Center",
                nepali: "बालविकास केन्द्र",
            },
            {
                value: "aadhaarbhutschool",
                english: "Basic School",
                nepali: "आधारभूत तह (१-५)",
            },
            {
                value: "primaryschool",
                english: "Primary School",
                nepali: "आधारभूत तह (१-८)",
            },
            {
                value: "maadhyamikschool",
                english: "Secondary School",
                nepali: "माध्यमिक तह (९-१०)",
            },
            {
                value: "maadhyamiknextschool",
                english: "Secondary School",
                nepali: "माध्यमिक तह (११-१२)",
            },
            {
                value: "bachlorsschool",
                english: "bachlorsschool",
                nepali: "स्नातक तह",
            },
            {
                value: "mastersschool",
                english: "mastersschool",
                nepali: "स्नातकोत्तर  तह",
            },
            //     {
            //         value: "others",
            //         english: "Others",
            //         nepali: "अन्य",
            //     }
        ],
    },
    {
        icon: "banking",
        english: "Bank Institution",
        nepali: "बैंक तथा वित्तीय सँस्था",
        count: 108,
        children: [
            {
                value: "aclassbank",
                english: "Aclassbank",
                nepali: "क वर्ग",
            },
            {
                value: "bclassbank",
                english: "Bclassbank",
                nepali: "ख वर्ग",
            },
            {
                value: "cclassbank",
                english: "Cclassbank",
                nepali: "ग वर्ग",
            },
            {
                value: "dclassbank",
                english: "Dclassbank",
                nepali: "घ वर्ग",
            },
            {
                value: "cooperativebank",
                english: "cooperativebank",
                nepali: "सहकारी सँस्था",
            },
            {
                value: "insurancebank",
                english: "nsurancebank",
                nepali: "विमा सँस्था",
            },
        ],
    },
    {
        icon: "government",
        english: "Government Institution",
        nepali: "सरकारी कार्यालय",
        count: 12,
        children: [
            {
                value: "electricitygovernmentoffice",
                english: "electricitygovernmentoffice",
                nepali: "विधुत प्राधिकरण कार्यालय ",
            },
            {
                value: "forestgovernmentoffice",
                english: "forestgovernmentoffice",
                nepali: "वन कार्यालय",
            },
            {
                value: "postgovernmentoffice",
                english: "postgovernmentoffice",
                nepali: "हुलाक कार्यालय",
            },
            {
                value: "taxgovernmentoffice",
                english: "taxgovernmentoffice",
                nepali: "कर कार्यालय",
            },
            {
                value: "weathergovernmentoffice",
                english: "weathergovernmentoffice",
                nepali: "मौसम विभाग",
            },
        ],
    },
    {
        icon: "building",
        english: "Intitution",
        nepali: "संघ सँस्थाहरु",
        count: 42,
        children: [
            {
                value: "ngoassociation",
                english: "ngoassociation",
                nepali: "गैर सरकारी",
            },
            {
                value: "privateassociation",
                english: "privateassociation",
                nepali: "संस्थागत (निजि)",
            },
            {
                value: "postgovernmentoffice",
                english: "postgovernmentoffice",
                nepali: "बालगृह/वृद्राश्रम /संचालित पुन: संस्थापना केन्द्र",
            },
            {
                value: "partyassociation",
                english: "partyassociation",
                nepali: "राजनैतिक पार्टी कार्यालय",
            },
        ],
    },
    {
        icon: "police-station",
        english: "Security body Offices",
        nepali: "सुरक्षा निकाय सम्बन्धी",
        count: 11,
        children: [
            {
                value: "policesecurity",
                english: "policesecurity",
                nepali: "इलाका प्रहरी कार्यालय",
            },
            {
                value: "policeofficesecurity",
                english: "policeofficesecurity",
                nepali: "प्रहरी चौकी",
            },
            {
                value: "temporarypolicesecurity",
                english: "temporarypolicesecurity",
                nepali: "अस्थायी प्रहरी विट",
            },
            {
                value: "apfsecurity",
                english: "apfsecurity",
                nepali: "एपीएफ व्यारेक",
            },
            {
                value: "trafficsecurity",
                english: "Traffic Office",
                nepali: "ट्राफिक कार्यालय",
            },
        ],
    },
    {
        icon: "building",
        english: "समुदायमा आधारित सँस्था समूह",
        nepali: "समुदायमा आधारित सँस्था समूह",
        count: 119,
        children: [
            {
                value: "agriorganization",
                english: "agriorganization",
                nepali: "कृषक समूह",
            },
            {
                value: "forestorganization",
                english: "forestorganization",
                nepali: "वन समूह",
            },
            {
                value: "motherorganization",
                english: "motherorganization",
                nepali: "आमा समूह",
            },
            {
                value: "bachatorganization",
                english: "bachatorganization",
                nepali: "वचत समूह",
            },
            {
                value: "toleorganization",
                english: "toleorganization",
                nepali: "टोल विकास संस्था",
            },
            {
                value: "paramparagatorganization",
                english: "paramparagatorganization",
                nepali: "परम्परागत समूह",
            },
            {
                value: "childorganization",
                english: "childorganization",
                nepali: "बाल क्लब",
            },
            {
                value: "youthorganization",
                english: "youthorganization",
                nepali: "युवा क्लब",
            },
            {
                value: "apaangaorganization",
                english: "apaangaorganization",
                nepali: "अपाङ्गता संजाल समूह",
            },
        ],
    },
    {
        icon: "hospital",
        english: "Health Institution",
        nepali: "स्वास्थ सँस्था",
        count: 33,
        children: [
            {
                value: "hospitalhealth",
                english: "hospitalhealth",
                nepali: "अस्पताल",
            },
            {
                value: "healthposthealth",
                english: "healthposthealth",
                nepali: "स्वास्थ्य चौकी",
            },
            {
                value: "swasthyaikaaihealth",
                english: "swasthyaikaaihealth",
                nepali: "सामुदायिक स्वास्थ्य इकाई",
            },
            {
                value: "primaryhealth",
                english: "primaryhealth",
                nepali: "प्राथमिक स्वास्थ्य केन्द्र",
            },
            {
                value: "basichealth",
                english: "basichealth",
                nepali: "आधारभूत स्वास्थ्य सेवा केन्द्र",
            },
            {
                value: "urbanhealth",
                english: "urbanhealth",
                nepali: "शहरी स्वास्थ्य केन्द्र",
            },
            {
                value: "villagehealth",
                english: "villagehealth",
                nepali: "गाउँ घर क्लिनिक",
            },
            {
                value: "eyehealth",
                english: "eyehealth",
                nepali: "आँखा उपचार केन्द्र",
            },
        ],
    },
    {
        icon: "hospital",
        english: "Health Institution Types",
        nepali: "स्वास्थ्य सँस्थाको प्रकार",
        count: 33,
        children: [
            {
                value: "personalhealth",
                english: "personalhealth",
                nepali: "संस्थागत (निजि)",
            },
            {
                value: "governmentalhealth",
                english: "governmentalhealth",
                nepali: "सामुदायिक",
            },
        ],
    },
    {
        icon: "city-hall",
        english: "Business",
        nepali: "व्यापार व्यावसाय",
        count: 4359,
        children: [
            {
                value: "kiranabusiness",
                english: "kiranabusiness",
                nepali: "किराना /थोक /मार्ट पसल",
            },
            {
                value: "clothbusiness",
                english: "clothbusiness",
                nepali: "कपडा पसल / फेन्सी स्टोर",
            },
            {
                value: "hardwarebusiness",
                english: "hardwarebusiness",
                nepali: "हार्डवेयर तथा निर्माण सामग्री व्यवसाय",
            },
            {
                value: "colddrinksbusiness",
                english: "colddrinksbusiness",
                nepali:
                    "चुरोट, मदिराजन्य , सुर्तीजन्य वस्तुहरुको व्यापार व्यवसाय",
            },
            {
                value: "electronicsbusiness",
                english: "electronicsbusiness",
                nepali:
                    "इलेक्ट्रोनिक्स सामग्रीहरुको थोक तथा खुद्र व्यवसाय (मोबाइल ,कम्प्युटर ,एसी, टिभी,फ्रिज ,क्यामेरा पंखा लगायत सामग्री )",
            },
            {
                value: "automobilebusiness",
                english: "automobilebusiness",
                nepali:
                    "अटो मोबाइल्स (ट्याक्टर विक्री , कार जिप ,मोटर पार्ट्स ,मेसिनरी सामग्रीको सोरुम तथा व्यावसाय )",
            },
            {
                value: "medicineclinicbusiness",
                english: "medicineclinicbusiness",
                nepali: "औषधी पसल वा क्लिनिक (डेन्टल क्लिनिक सहित )",
            },
            {
                value: "hotelresturentbusiness",
                english: "hotelresturentbusiness",
                nepali:
                    "होटेल तथा रेष्टुरेन्ट, रिसोर्ट , तारे होटल, खाजा घर (चिया नास्ता पसल सहित )",
            },
            {
                value: "jwellerybusiness",
                english: "jwellerybusiness",
                nepali: "ज्वेलरी , सुन चादीका गरगहना पसल",
            },
            {
                value: "consultingbusiness",
                english: "consultingbusiness",
                nepali:
                    "इलेक्ट्रिकल, वायारिङका सामग्रीहरुको थोक तथा खुद्रा व्यावसाय",
            },
            {
                value: "counselingbusiness",
                english: "counselingbusiness",
                nepali:
                    "परामार्श सेवा, अनुसन्धान तथा परामर्शदाता ,छापाखाना , प्रेस स्क्रिन , वृत्तचित्र निर्माण , सर्भेयर प्रिन्टर आदि व्यवसाय",
            },
            {
                value: "millbusiness",
                english: "millbusiness",
                nepali: "कुटानी /पिसानी व्यवसाय",
            },
            {
                value: "utensilbusiness",
                english: "utensilbusiness",
                nepali: "भाडावर्तन",
            },
            {
                value: "parlorbusiness",
                english: "parlorbusiness",
                nepali: "पार्लर ,कस्मेटिक , बुटिक,सिलाई कटाई गर्ने व्यवसाय",
            },
            {
                value: "meatbusiness",
                english: "meatbusiness",
                nepali: "मासुजन्य पसल",
            },
            {
                value: "vegitebalefruitsbusiness",
                english: "vegitebalefruitsbusiness",
                nepali: "तरकारी तथा फलफूल व्यवसाया",
            },
            {
                value: "dairybusiness",
                english: "dairybusiness",
                nepali: "दुध डेरी तथा चिस्यान केन्द्र",
            },
            {
                value: "vetnarybusiness",
                english: "vetnarybusiness",
                nepali: "भेटरीनरी पसल",
            },
            {
                value: "kewalbusiness",
                english: "kewalbusiness",
                nepali: "केवल नेटवर्क /इन्टरनेट सेवा प्रदायक किकाय /कम्पनी",
            },
            {
                value: "jimbusiness",
                english: "jimbusiness",
                nepali: "शारीरिक व्यायामशाला",
            },
            {
                value: "playgroundbusiness",
                english: "playgroundbusiness",
                nepali: "फुटसल संचालन",
            },
            {
                value: "workshopbusiness",
                english: "workshopbusiness",
                nepali: "वर्कशप",
            },
            {
                value: "partypalacebusiness",
                english: "partypalacebusiness",
                nepali: "पार्टी प्यालेस",
            },
            {
                value: "sajawatbusiness",
                english: "sajawatbusiness",
                nepali:
                    "कोल्ड ड्रिंक्स ,सिसा फ्रेमिङ, कार्पेट , सजावटका सामान , जनरल गुड्स आदि",
            },
            {
                value: "kawadibusiness",
                english: "kawadibusiness",
                nepali: "कवाडी पसल थोक तथा खुद्रा",
            },
            {
                value: "gasbusiness",
                english: "gasbusiness",
                nepali: "ग्यास बिक्रि डिलर",
            },
            {
                value: "petrolbusiness",
                english: "petrolbusiness",
                nepali: "पेट्रोल पम्प",
            },
            {
                value: "tenthousebusiness",
                english: "tenthousebusiness",
                nepali: "टेन्टहाउस क्याटरिङ सेवा (भए नभएको दुवै )",
            },
            {
                value: "menpowerbusiness",
                english: "menpowerbusiness",
                nepali:
                    "वैदेशिक रोजगारमा पठाउँने सन्स्था , मेनपावर , विदेशको लागि जनशक्ति निर्यात गर्ने कम्पनी",
            },
            {
                value: "furnicherbusiness",
                english: "furnicherbusiness",
                nepali: "फर्निचर पसल",
            },
            {
                value: "tutionbusiness",
                english: "tutionbusiness",
                nepali: "तालिम केन्द्र / सिकाई केन्द्र",
            },
            {
                value: "housingcompanybusiness",
                english: "housingcompanybusiness",
                nepali: "घरजग्गा खरिद विक्री , हाउजिङ कम्पनी",
            },
            {
                value: "photostudiobusiness",
                english: "photostudiobusiness",
                nepali: "कलर ल्याब /फोटो स्टुडियो",
            },
            {
                value: "argfambusiness",
                english: "argfambusiness",
                nepali: "कृषि, पशु तथा माछा फर्म",
            },
            {
                value: "lawformbusiness",
                english: "lawformbusiness",
                nepali: "कानून व्यवसाय तथा परामर्श",
            },
            {
                value: "colourbusiness",
                english: "colourbusiness",
                nepali: "पेन्ट्स / रङ्ग पसल तथा व्यवसाय",
            },
            {
                value: "mallbusiness",
                english: "mallbusiness",
                nepali: "मल तथा विषादी व्यवसय",
            },
            {
                value: "stationarybusiness",
                english: "stationarybusiness",
                nepali: "स्टेसनरी पसल",
            },
            {
                value: "godaambusiness",
                english: "godaambusiness",
                nepali: "गोदाम",
            },
            // {
            //     value: "othersbusiness",
            //     english: "othersbusiness",
            //     nepali: "अन्य",
            // },
        ],
    },
    {
        icon: "factory",
        english: "Industries",
        nepali: "उधोग तथा कलकारखाना",
        count: 179,
        children: [
            {
                value: "kaagajindustry",
                english: "kaagajindustry",
                nepali: "कागज उद्दोग",
            },
            {
                value: "galaichaindustry",
                english: "galaichaindustry",
                nepali: "गलैचा उद्दोग",
            },
            {
                value: "brickindustry",
                english: "brickindustry",
                nepali: "इँटा भट्टा",
            },
            {
                value: "sugarindustry",
                english: "sugarindustry",
                nepali: "चिनी उधोग",
            },
            {
                value: "krasarindustry",
                english: "krasarindustry",
                nepali: "क्रसर उद्दोग",
            },
            {
                value: "bacakaryindustry",
                english: "bacakaryindustry",
                nepali: "बेकरी/बिस्कुट उद्दोग",
            },
            {
                value: "woodindustry",
                english: "woodindustry",
                nepali: "काठजन्य फर्निचर उद्दोग",
            },
            {
                value: "millindustry",
                english: "millindustry",
                nepali: "मिल",
            },
            {
                value: "grillindustry",
                english: "grillindustry",
                nepali: "ग्रिल उद्दोग",
            },
            {
                value: "grillindustry",
                english: "grillindustry",
                nepali: "ग्रिल उद्दोग",
            },
            // {
            //     value: "othersindustry",
            //     english: "othersindustry",
            //     nepali: "अन्य",
            // },
        ],
    },
    {
        icon: "factory",
        english: "Industries",
        nepali: "उद्दोगको प्रकार",
        count: 179,
        children: [
            {
                value: "houseindustry",
                english: "houseindustry",
                nepali: "घरेलु उद्दोग",
            },
            {
                value: "mediumindustry",
                english: "mediumindustry",
                nepali: "मझौला उद्दोग",
            },
            {
                value: "bigindustry",
                english: "bigindustry",
                nepali: "ठुला उद्दोग",
            },
            {
                value: "laghuindustry",
                english: "laghuindustry",
                nepali: "लघु",
            },
            // {
            //     value: "otherstypeindustry",
            //     english: "otherstypeindustry",
            //     nepali: "अन्य",
            // },
        ],
    },
    {
        icon: "infrastructure",
        english: "Infrastructure",
        nepali: "भौतिक पूर्वाधार",
        count: 848,
        children: [
            {
                value: "bridgeinfrastructure",
                english: "bridgeinfrastructure",
                nepali: "पुल",
            },
            {
                value: "stadiuminfrastructure",
                english: "stadiuminfrastructure",
                nepali: "खेल मैदान",
            },
            {
                value: "helipadinfrastructure",
                english: "helipadinfrastructure",
                nepali: "हेलीप्याड",
            },
            {
                value: "electricityinfrastructure",
                english: "electricityinfrastructure",
                nepali: "जलविधुत आयोजना",
            },
            {
                value: "atminfrastructure",
                english: "atminfrastructure",
                nepali: "ATM (एटीएम)",
            },
            {
                value: "culvertinfrastructure",
                english: "culvertinfrastructure",
                nepali: "कल्भर्ट",
            },
            {
                value: "buildinginfrastructure",
                english: "buildinginfrastructure",
                nepali: "सामुदायिक भवन",
            },
            {
                value: "towerinfrastructure",
                english: "towerinfrastructure",
                nepali: "संचार टावर",
            },
            {
                value: "tankinfrastructure",
                english: "tankinfrastructure",
                nepali: "खानेपानी ट्यांकी भएको स्थान",
            },
            {
                value: "crematoriainfrastructure",
                english: "crematoriainfrastructure",
                nepali: "शवदहास्थल",
            },
            {
                value: "kuloinfrastructure",
                english: "kuloinfrastructure",
                nepali: "बाँध /कुलो /मुहान /नहर",
            },
        ],
    },
    {
        icon: "nature",
        english: "Natural Resources",
        nepali: "प्राकृतिक श्रोत",
        count: 20,
        children: [
            {
                value: "miningnature",
                english: "miningnature",
                nepali: "खानी क्षेत्र",
            },
            {
                value: "waterfallnature",
                english: "waterfallnature",
                nepali: "झरना",
            },
            {
                value: "cavenature",
                english: "cavenature",
                nepali: "गुफा",
            },
            {
                value: "simsaarnature",
                english: "simsaarnature",
                nepali: "सिमसार",
            },
            {
                value: "pondnature",
                english: "pondnature",
                nepali: "पोखरी ",
            },
            {
                value: "lakenature",
                english: "lakenature",
                nepali: "ताल",
            },
            {
                value: "mainwaternature",
                english: "mainwaternature",
                nepali: "मुख्य पानी मुहान",
            },
            // {
            //     value: "otherswaternature",
            //     english: "otherswaternature",
            //     nepali: "अन्य",
            // },
        ],
    },
    {
        icon: "park",
        english: "Public Places",
        nepali: "सार्वजनिक स्थल",
        count: 18,
        children: [
            {
                value: "toiletpublic",
                english: "toiletpublic",
                nepali: "सर्वाजनिक सौचालय",
            },
            {
                value: "wifipublic",
                english: "wifipublic",
                nepali: "फ्री वाईफाई जोन",
            },
            {
                value: "buspublic",
                english: "buspublic",
                nepali: "वसपार्क तथा बस बिसौनी स्थान",
            },
            {
                value: "safaripublic",
                english: "safaripublic",
                nepali: "सफारी स्टेन्ड",
            },
            {
                value: "chautaarapublic",
                english: "chautaarapublic",
                nepali: "चौतारा",
            },
            {
                value: "patipauwapublic",
                english: "patipauwapublic",
                nepali: "पाटी/पौवा",
            },
            {
                value: "parkpublic",
                english: "parkpublic",
                nepali: "पार्क",
            },
        ],
    },
    {
        icon: "temple",
        english: "Cultural Heritage",
        nepali: "धार्मिक, साँस्कृतिक तथा एतिहासिक स्थल",
        count: 203,
        children: [
            {
                value: "templereligion",
                english: "templereligion",
                nepali: "मठमन्दिर",
            },
            {
                value: "mosquepublic",
                english: "mosquepublic",
                nepali: "मस्जिद",
            },
            {
                value: "gumbapublic",
                english: "gumbapublic",
                nepali: "गुम्बा",
            },
            {
                value: "dharmasalapublic",
                english: "dharmasalapublic",
                nepali: "धर्मशाला",
            },
            {
                value: "museumpublic",
                english: "museumpublic",
                nepali: "संग्राहालय",
            },
            {
                value: "palacepublic",
                english: "palacepublic",
                nepali: "दरवार",
            },
            {
                value: "culturalclubpublic",
                english: "culturalclubpublic",
                nepali: "साँस्कृतिक क्लब (नाचघर)",
            },
            {
                value: "charchpublic",
                english: "charchpublic",
                nepali: "चर्च",
            },
            {
                value: "saakelathaanpublic",
                english: "saakelathaanpublic",
                nepali: "अस्पसाकेला थानताल",
            },
            {
                value: "otherspublic",
                english: "otherspublic",
                nepali: "अन्य",
            },
        ],
    },
    {
        icon: "nature",
        value: "naturaldisaster",
        english: "naturaldisaster",
        nepali: "विपद प्रकोप जोखिम क्षेत्र",
        count: 11,
    },
    {
        icon: "nature",
        value: "touristspot",
        english: "touristspot",
        nepali: "पर्यटकीय स्थल",
        count: 10,
    },
    {
        icon: "book",
        value: "wardofficespot",
        english: "wardofficespot",
        nepali: "गाउँपालिका र वडा कार्यालय",
        count: 14,
    },
    // {
    // 	icon: "health",
    // 	english: "Health Institution",
    // 	nepali: "स्वास्थ सँस्था",
    //     children: [
    //         {
    //             value: "hospitalhealth",
    //             english: "hospitalhealth",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //             value: "forestorganization",
    //             english: "forestorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //             value: "motherorganization",
    //             english: "motherorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //             value: "bachatorganization",
    //             english: "bachatorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //         	value: "toleorganization",
    //         	english: "toleorganization",
    //         	nepali: "अस्पताल",
    //         },
    //         {
    //             value: "paramparagatorganization",
    //             english: "paramparagatorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //             value: "childorganization",
    //             english: "childorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //             value: "youthorganization",
    //             english: "youthorganization",
    //             nepali: "अस्पताल",
    //         },
    //         {
    //         	value: "apaangaorganization",
    //         	english: "apaangaorganization",
    //         	nepali: "अस्पताल",
    //         },
    //     ],
    // },
];

export const ward = [
    {
        backgroundColor: "#f44336",
        key: 1,
        value: 1,
        ward: "वडा न. १",
        english: "ward 1",
        nepali: "वडा न. १",
    },
    {
        key: 2,
        value: 2,
        backgroundColor: "#ff5722",
        ward: "वडा न. २ ",
        english: "ward 2",
        nepali: "वडा न. २ ",
    },
    {
        key: 3,
        value: 3,
        backgroundColor: "#673ab7",
        ward: "वडा न. ३",
        english: "ward 3",
        nepali: "वडा न. ३",
    },
    {
        key: 4,
        value: 4,
        backgroundColor: "#e91e63",
        ward: "वडा न. ४",
        english: "ward 4",
        nepali: "वडा न. ४",
    },
    {
        key: 5,
        value: 5,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ५",
        english: "ward 5",
        nepali: "वडा न. ५",
    },
    {
        key: 6,
        value: 6,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ६",
        english: "ward 6",
        nepali: "वडा न. ६",
    },
    {
        key: 7,
        value: 7,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ७",
        english: "ward 7",
        nepali: "वडा न. ७",
    },
    {
        key: 8,
        value: 8,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ८",
        english: "ward 8",
        nepali: "वडा न. ८",
    },
    {
        key: 9,
        value: 9,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ९",
        english: "ward 9",
        nepali: "वडा न. ९",
    },
    {
        key: 10,
        value: 10,
        backgroundColor: "#9c27b0",
        ward: "वडा न. १०",
        english: "ward 10",
        nepali: "वडा न. १०",
    },
    {
        key: 11,
        value: 11,
        backgroundColor: "#9c27b0",
        ward: "वडा न. ११",
        english: "ward 11",
        nepali: "वडा न. ११",
    },
    {
        key: 12,
        value: 12,
        backgroundColor: "#9c27b0",
        ward: "वडा न. १२",
        english: "ward 12",
        nepali: "वडा न. १२",
    },
    {
        key: 13,
        value: 13,
        backgroundColor: "#9c27b0",
        ward: "वडा न. १३",
        english: "ward 13",
        nepali: "वडा न. १३",
    },
    {
        key: 14,
        value: 14,
        backgroundColor: "#9c27b0",
        ward: "वडा न. १४",
        english: "ward 14",
        nepali: "वडा न. १४",
    },
    {
        key: 15,
        value: 15,
        backgroundColor: "#9c27b0",
        ward: "वडा न. १५",
        english: "ward 15",
        nepali: "वडा न. १५",
    },
];

export const wardSubNav = [
    {
        english: "Ward",
        nepali: "वडा",
        subNav: [
            {
                backgroundColor: "#f44336",
                key: 1,
                value: 1,
                ward: "वडा न. १",
                english: "ward 1",
                nepali: "वडा न. १",
            },
            {
                key: 2,
                value: 2,
                backgroundColor: "#ff5722",
                ward: "वडा न. २ ",
                english: "ward 2",
                nepali: "वडा न. २ ",
            },
            {
                key: 3,
                value: 3,
                backgroundColor: "#673ab7",
                ward: "वडा न. ३",
                english: "ward 3",
                nepali: "वडा न. ३",
            },
            {
                key: 4,
                value: 4,
                backgroundColor: "#e91e63",
                ward: "वडा न. ४",
                english: "ward 4",
                nepali: "वडा न. ४",
            },
            {
                key: 5,
                value: 5,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ५",
                english: "ward 5",
                nepali: "वडा न. ५",
            },
            {
                key: 6,
                value: 6,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ६",
                english: "ward 6",
                nepali: "वडा न. ६",
            },
            {
                key: 7,
                value: 7,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ७",
                english: "ward 7",
                nepali: "वडा न. ७",
            },
            {
                key: 8,
                value: 8,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ८",
                english: "ward 8",
                nepali: "वडा न. ८",
            },
            {
                key: 9,
                value: 9,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ९",
                english: "ward 9",
                nepali: "वडा न. ९",
            },
            {
                key: 10,
                value: 10,
                backgroundColor: "#9c27b0",
                ward: "वडा न. १०",
                english: "ward 10",
                nepali: "वडा न. १०",
            },
            {
                key: 11,
                value: 11,
                backgroundColor: "#9c27b0",
                ward: "वडा न. ११",
                english: "ward 11",
                nepali: "वडा न. ११",
            },
            {
                key: 12,
                value: 12,
                backgroundColor: "#9c27b0",
                ward: "वडा न. १२",
                english: "ward 12",
                nepali: "वडा न. १२",
            },
            {
                key: 13,
                value: 13,
                backgroundColor: "#9c27b0",
                ward: "वडा न. १३",
                english: "ward 13",
                nepali: "वडा न. १३",
            },
            {
                key: 14,
                value: 14,
                backgroundColor: "#9c27b0",
                ward: "वडा न. १४",
                english: "ward 14",
                nepali: "वडा न. १४",
            },
            {
                key: 15,
                value: 15,
                backgroundColor: "#9c27b0",
                ward: "वडा न. १५",
                english: "ward 15",
                nepali: "वडा न. १५",
            },
        ],
    },
];

export const baseMap = [
    {
        name: "OpenStreetMap.Mapnik",
        checked: true,
        attribution:
            '<a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    // {
    // 	checked: false,
    // 	name: "OpenStreetMap.BlackAndWhite",
    // 	attribution:
    // 		'<a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    // 	url: "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
    // },
    // {
    // 	checked: false,
    // 	name: "heigit.openmapsurfer",
    // 	attribution:
    // 		'<a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    // 	url:
    // 		"https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png"
    // },
    // {
    // 	name: "Esri.NatGeoWorldMap",
    // 	checked: false,
    // 	attribution:
    // 		"Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC",
    // 	url:
    // 		"https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
    // },
    {
        name: "Esri.WorldStreetMap",
        checked: false,
        attribution: "Tiles &copy; Esri &mdash; Source: Esri",
        url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    },
    {
        name: "Esri.WorldSatellite",
        checked: false,
        attribution: "Tiles &copy; Esri &mdash; Source: Esri",
        url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    },
];
